import React, { Component } from 'react';
import styled from 'styled-components';
import { Trail, config, animated } from 'react-spring';
import { graphql } from 'gatsby';
import {
  Container, Panel, Head, Sub, HideOnMobile, FlexLayout,
} from '../../../elements';
import Back from '../../../components/Back';
import PrintContainer from '../PrintContainer';
import Card from '../../../components/Card';
import Content from '../../../components/Content';
import AppContainer from '../../../components/AppContainer';
import PreviewCompatibleImage from '../../../components/PreviewCompatibleImage';

const PrintDistribution = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Container>
      <PrintContainer>
        <Head>{page.heading}</Head>
        {page.sections.map(section => (
          <React.Fragment key={section.id}>
            <Head>{section.heading}</Head>
            <Sub>{section.subHeading}</Sub>

            <Content content={section.content.childContentfulRichText.html} />
            <FlexLayout>
            {section.items
              && section.items.map(({ id, label, extraInfo, infoList, text }) => (
                <Card
                key={id}
                fullWidth
                item={{
                  label,
                  text,
                  extraInfo
                }}
                />
                ))}
                </FlexLayout>
          </React.Fragment>
        ))}
      </PrintContainer>
      <HideOnMobile style={{ backgroundColor: 'white' }}>
        <PreviewCompatibleImage imageInfo={page.image} />
      </HideOnMobile>
    </Container>
  </AppContainer>
);

export const printDistributionPageQuery = graphql`
  query PrintDistributionPageQuery($id: String!) {
    contentfulTable(title: { eq: "config" }) {
      childContentfulTableTableJsonNode {
        internal {
          content
        }
      }
    }

    contentfulPage(id: { eq: $id }) {
      title
      image {
        fluid(maxWidth: 1500, quality: 70) {
          ...GatsbyContentfulFluid
        }
      }
      sections {
        id
        heading
        subHeading
        content {
          childContentfulRichText {
            html
          }
        }
        items {
          id
          label
          infoList
          extraInfo
          text {
            text
          }
        }
      }
    }
  }
`;

export default PrintDistribution;
